import React from "react";
import { connect } from "react-redux";
import { getWebsiteConfig } from "../../../services/firebaseService/endPoints/admin/website";
import LoadingModal from "../../commons/LoadingModal";
import { Alert, Input, Modal, Spin } from "antd";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import moment from "moment";
import { uploadImage } from "../../../services/firebaseService/endPoints/admin/members";
import ImageUploadButton from "../website/ImageUploaderButton";
import { db } from "../../../services/firebaseService/connection";
import Axios from "axios";
import GetStarted from "./getStarted";
import { getLocations } from "../../../services/firebaseService/endPoints/admin/content";
import { doc, getDoc } from "firebase/firestore";
import DirectoryGeneral from "./directory-general";
import DirectoryImages from "./directory-images";
import logger from "../../../utils/logger";
import {
  setGettingStartedExpanded,
  setGettingStartedItemStatus,
} from "../../../redux/actions/adminData";
import {
  GUIDE_GROW,
  TOPIC_PUBLISH_GROUP,
} from "../../../constants/gettingStarted";
import CreateLocations from "../locations/CreateLocation/CreateLocations";

const LOCATION_LIMIT = 10;

class DirectoryAdd extends React.Component {
  state = {
    isLoading: false,
    photo: null,
    locations: [],
    selectedLocations: [],
    watchparty: {
      image: null,
      locationData: {
        placeId: "",
        address: "",
        latitude: 33.7676338,
        longitude: -84.5606888,
      },
    },
    tailgate: {
      image: null,
      locationData: {
        placeId: "",
        address: "",
        latitude: 33.7676338,
        longitude: -84.5606888,
      },
    },
    contact: {},
    general: {},
    groupImages: { image1: "", image2: "", image3: "", image4: "" },
    summary: "",
    groupOverview: "",
    locationType: "",
    tab: "directory",
    isSaving: false,
    showModal: false,
    showWelcomeModal: false,
    errorInForm: {
      isError: false,
      visible: false,
      message: "",
    },
    isPublished: false,
  };

  handleErrorMessageClose = () => {
    this.setState((prevState) => {
      const errorInForm = {
        ...prevState.errorInForm,
      };
      errorInForm.visible = false;
      return {
        errorInForm,
      };
    });
  };

  locationClear = (e) => {
    e.persist();
    this.setState((prevState) => {
      const locationData = {
        ...prevState[e.target.name].locationData,
      };
      locationData.placeId = "";
      locationData.address = "";
      return {
        [e.target.name]: {
          location: "",
          locationData,
        },
        locationType: "",
      };
    });
  };

  handleInputChange = (e) => {
    e.persist();
    if (e.target.name.includes(".")) {
      let keyParts = e.target.name.split(".");
      this.setState(
        (prevState) => {
          let updateObj = { ...prevState };
          updateObj[keyParts[0]][keyParts[1]] = e.target.value;
          return updateObj;
        },
        () => {
          logger.log(this.state);
        }
      );
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {
        logger.log(this.state);
      });
    }
  };

  handleModalChange = () => {
    this.setState(() => {
      return {
        showModal: !this.state.showModal,
      };
    });
  };
  mapUpdate = (e) => {
    this.setState((prevState) => {
      const locationData = {
        ...prevState[prevState.locationType].locationData,
      };
      // placeId: e.placeId,
      locationData.address = e.address;
      locationData.placeId = e.placeId;
      locationData.longitude = e.long;
      locationData.latitude = e.lat;
      return {
        [prevState.locationType]: {
          ...prevState[prevState.locationType],
          locationData,
        },
        locationType: "",
      };
    });
  };

  handleOptionChange = (e) => {
    e.persist();
    if (e.target.name.includes(".")) {
      let keyParts = e.target.name.split(".");
      this.setState(
        (prevState) => {
          let updateObj = { ...prevState };
          updateObj[keyParts[0]][keyParts[1]] = e.target.value;
          return updateObj;
        },
        () => {
          logger.log(this.state);
        }
      );
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {
        logger.log(this.state);
      });
    }
  };

  handleImageChange = (key, image) => {
    const obj = { ...this.state.groupImages };
    obj[key] = image;
    this.setState({
      groupImages: obj,
    });
  };

  handleDeleteLocation = (index) => {
    let selectedLocations = this.state.selectedLocations;
    selectedLocations.splice(index, 1);

    this.setState({
      selectedLocations,
    });
  };

  handleSubmit = async () => {
    try {
      this.setState({
        isSaving: true,
      });
      let groupData = {
        sections: {
          selectedLocations: [],
        },
        groupSummary: this.state.summary,
        groupOverview: this.state.groupOverview,
        social: {
          facebook: this.state.contact.facebook
            ? this.state.contact.facebook.startsWith("https://")
              ? this.state.contact.facebook
              : `https://${this.state.contact.facebook}`
            : "",
          insta: this.state.contact.insta
            ? this.state.contact.insta.startsWith("https://")
              ? this.state.contact.insta
              : `https://${this.state.contact.insta}`
            : "",
          twitter: this.state.contact.twitter
            ? this.state.contact.twitter.startsWith("https://")
              ? this.state.contact.twitter
              : `https://${this.state.contact.twitter}`
            : "",
        },
        groupType: this.state.general.clubStatus,
        general: {
          founded: this.state.general.founded,
          size: this.state.general.groupSize,
          membershipFee: this.state.general.membershipFee,
        },
        contact: {
          email: this.state.contact.email,
          website: this.state.contact.website
            ? this.state.contact.website.startsWith("https://")
              ? this.state.contact.website
              : `https://${this.state.contact.website}`
            : "",
        },
        showGroup: true,
      };

      const storageDirectory = `${this.props.currentGroup}/profile`;

      let photo = this.state.photo;

      if (photo && typeof photo === "object") {
        photo = await uploadImage(
          this.state.photo,
          `${storageDirectory}/photo.png`
        ).then(({ downloadUrl }) => downloadUrl);
      } else if (!photo) {
        this.setState({
          errorInForm: {
            isError: true,
            visible: true,
            message: "Group Photo is required.",
          },
          isSaving: false,
        });
        window.scrollTo(0, 0);
        return;
      }

      let imagesToUpload = { ...this.state.groupImages };
      let imagePromisesArr = [];

      if (this.state.groupImages) {
        Object.entries(this.state.groupImages).forEach(([key, value]) => {
          if (typeof value === "object") {
            imagePromisesArr.push({
              key: key,
              promise: uploadImage(
                value,
                `${storageDirectory}/groupImage-${key}.png`
              ),
            });
          } else if (typeof value === "string") {
            imagesToUpload[key] = value;
          }
        });

        const resultMap = await Promise.all(
          imagePromisesArr.map((item) => item.promise)
        )
          .then((results) => {
            return imagePromisesArr.map((item, index) => {
              return {
                [item.key]: results[index].downloadUrl,
              };
            });
          })
          .catch(() => {
            this.setState({
              errorInForm: {
                isError: true,
                visible: true,
                message: "Failed to upload images.",
              },
              isSaving: false,
            });
            window.scrollTo(0, 0);
            return;
          });

        imagesToUpload = {
          ...this.state.groupImages,
          ...Object.assign({}, ...resultMap),
        };
      }

      if (!this.state.summary) {
        this.setState({
          errorInForm: {
            isError: true,
            visible: true,
            message: "Summary is required.",
          },
          isSaving: false,
        });
        window.scrollTo(0, 0);
        return;
      }
      if (this.state.selectedLocations.length === 0) {
        this.setState({
          errorInForm: {
            isError: true,
            visible: true,
            message: "Please add location(s) to Where We Meet.",
          },
          isSaving: false,
        });
        window.scrollTo(0, 0);
        return;
      }

      groupData.sections.selectedLocations = this.state.selectedLocations;

      let configuration = {
        photo,
        groupImages: imagesToUpload,
        logo: this.props.adminData.data
          ? this.props.adminData.data.configuration.logo
          : "",
      };

      let clubColors = {
        primaryColor: { a: "1", r: 0, g: 0, b: 0 },
        secondaryColor: { a: "1", r: 0, g: 0, b: 0 },
        stripesColor: { a: "1", r: 0, g: 0, b: 0 },
        textPrimaryColor: "#FFFFFF",
      };
      let clubId = "";
      let leagueId = "";
      let clubName = "";
      let leagueName = "";

      await getDoc(doc(db, "group", this.props.currentGroup)).then((doc) => {
        let data = doc.data() || {};
        if (!data.configuration) {
          return getDoc(doc(db, "clubColors", data.clubId)).then((doc) => {
            if (doc.exists()) {
              var data = doc.data() || {};
              if (data !== null) {
                clubColors.primaryColor = data["color1"];
                clubColors.secondaryColor = data["color2"];
                clubColors.stripesColor = data["color3"];
              }
              configuration = {
                ...configuration,
                ...clubColors,
              };
            }
          });
        } else {
          configuration = { ...configuration };
        }
        clubId = data.clubId || "";
        clubName = data.clubName || "";
        leagueId = data.leagueId || "";
        leagueName = data.leagueName || "";
        return;
      });

      let baseUrl =
        "https://us-central1-chant2019.cloudfunctions.net/addGroupToDirectory";
      let addProfileUrl = `${baseUrl}/addGroupProfile`;

      let addProfileReqBody = {
        groupProfile: {
          ...groupData,
          updatedOn: moment().unix(),
          configuration: configuration,
          clubId: clubId,
          leagueId: leagueId,
          clubName: clubName,
          leagueName: leagueName,
          groupName: this.props.adminData.data
            ? this.props.adminData.data.groupName
            : "",
        },
        groupId: this.props.currentGroup,
        type: "update",
      };

      await Axios.post(addProfileUrl, JSON.stringify(addProfileReqBody), {
        headers: {
          "content-type": "test/json",
        },
      });

      this.setState({
        isSaving: false,
        tab: "getStarted",
        showWelcomeModal: true,
      });

      this.props.dispatch(setGettingStartedExpanded(true));
    } catch {
      this.setState({
        isSaving: false,
      });
    }
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData &&
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    let groupLink = `https://directory.chant.fan/details/${this.props.currentGroup}`;
    return (
      <div className="col">
        {this.state.showModal ? (
          <div>
            <Modal
              visible={this.state.showModal}
              closable={true}
              width="150vh"
              onCancel={this.handleModalChange}
              footer={[]}
            >
              {/* {<Map onRef={ref => (this.child = ref)} mapUpdate={this.mapUpdate} lat={this.state.watchparty.locationData.latitude} long={this.state.watchparty.locationData.longitude} place_id={this.state.watchparty.locationData.placeId} />} */}
              <CreateLocations onCloseModal={this.handleModalChange} />
            </Modal>
          </div>
        ) : (
          <div></div>
        )}
        <div style={{ padding: "30px 16px" }}>
          <ul className="nav nav-tabs">
            <li
              className="nav-item"
              onClick={() => {
                this.setState({
                  tab: "directory",
                });
              }}
              style={
                this.state.tab === "directory"
                  ? {
                      borderBottom: `4px solid ${primaryColor}`,
                    }
                  : {}
              }
            >
              <a
                name="current"
                style={{
                  height: "100%",
                  fontWeight: "bold",
                  fontSize: "22px",
                  color: "#ffffff",
                }}
              >
                Publish Group
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                this.setState({
                  tab: "getStarted",
                });
              }}
              style={
                this.state.tab === "getStarted"
                  ? {
                      borderBottom: `4px solid ${primaryColor}`,
                      marginLeft: 24,
                    }
                  : {
                      marginLeft: 24,
                    }
              }
            >
              <a
                name="current"
                style={{
                  height: "100%",
                  fontWeight: "bold",
                  fontSize: "22px",
                  color: "#ffffff",
                }}
              >
                Get Started
              </a>
            </li>
          </ul>
          <LoadingModal loading={this.state.isLoading} />
          <div className="md-5 p-2 border-box">
            <div
              className="border rounded p-3 px-3"
              style={{
                backgroundColor: "#ffffff",
                // boxShadow: "0px 3px 10px 1px",
              }}
            >
              {this.state.tab === "directory" && (
                <>
                  {this.state.errorInForm.isError &&
                    this.state.errorInForm.visible && (
                      <div className="mx-5 mt-2">
                        <Alert
                          message="Error"
                          description={this.state.errorInForm.message}
                          type="error"
                          closable
                          // className="mb-3"
                          afterClose={this.handleErrorMessageClose}
                        />
                      </div>
                    )}
                  <div className="mb-2">
                    <p style={{ marginBottom: "0.5rem" }}>
                      Chant offers a free global supporters{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://directory.chant.fan`}
                      >
                        directory
                      </a>
                      . Complete the form to publish your group. Share your URL
                      in social media. It can also serve as a basic group
                      website.
                    </p>
                    <p>
                      {this.props?.adminData?.data?.groupName} URL:{" "}
                      {this.state.isPublished ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={groupLink}
                        >
                          Directory
                        </a>
                      ) : (
                        "Publish group for link"
                      )}
                    </p>
                  </div>

                  {/* <p>Your directory listing: {this.props.adminData && this.props.adminData.data.isGroupPublished ? <a href={groupLink} target='_blank' rel='noopener noreferrer'>{groupLink}</a> : 'unpublished'}</p> */}

                  <div className="row">
                    <div className="col">
                      <h5 style={{ fontWeight: "bold" }}>Group Profile</h5>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="form-group col-md-12">
                        <ImageUploadButton
                          label="Group Photo"
                          text="Upload"
                          aspectRatio={5 / 2}
                          images={[this.state.photo]}
                          required={true}
                          maxSizeMB={0.5}
                          tooltip="Image will be cropped at an aspect ratio of 5:2."
                          onSave={(images) => {
                            this.setState({
                              photo: images[0],
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginLeft: "16px" }}>
                    <div className="form-group row">
                      <label className="col-md-2">Highlight Statement</label>
                      <div
                        style={{ width: "66.66%", marginLeft: "-18px" }}
                        className="col-md-4"
                      >
                        <Input.TextArea
                          style={{ fontSize: `18px` }}
                          placeholder="Add brief highlight statement. Max 50 characters"
                          value={this.state.groupOverview}
                          name="groupOverview"
                          onChange={this.handleInputChange}
                          className="col"
                          maxLength={50}
                          rows={4}
                          // required
                        />
                        <div
                          className="d-flex"
                          style={{
                            justifyContent: "flex-end",
                            fontSize: "14px",
                          }}
                        >
                          Character count:{" "}
                          {`${this.state.groupOverview.length}/50`}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <p style={{ fontSize: "14px" }}>
                          Add a brief statement highlighting your group (First
                          official group in..., Longest active group in...,
                          Leading the stadium atmosphere, A diverse group open
                          to all...)
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-4">
                        Summary
                        {
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            {" "}
                            *{" "}
                          </span>
                        }
                      </label>
                      {/* <input className="form-control form-control-sm" placeholder="Join us for a season kickoff party at Fado!"></input> */}
                      <div style={{ width: "66.66%" }}>
                        <Input.TextArea
                          //   tabIndex="4"
                          style={{ fontSize: `18px` }}
                          placeholder="Provide an overview of your group. Max 1000 characters."
                          value={this.state.summary}
                          name="summary"
                          onChange={this.handleInputChange}
                          className="col"
                          maxLength={1000}
                          rows={4}
                          required
                          //   autosize={true}
                        />
                        <div
                          className="d-flex"
                          style={{
                            justifyContent: "flex-end",
                            fontSize: "14px",
                          }}
                        >
                          Character count: {`${this.state.summary.length}/1000`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row mt-4">
                        <div className="col-md-4">
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "bold" }}>
                              Where we meet
                            </h5>
                            <span style={{ color: "#ff0000" }}>*</span>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div
                            className="d-flex"
                            style={{ flexDirection: "column" }}
                          >
                            {this.state.selectedLocations.length > 0 &&
                              this.state.selectedLocations.map(
                                (selectedLocation, index) => (
                                  <div className="d-flex">
                                    <div className="d-flex border-box">
                                      {selectedLocation.type}:
                                      {selectedLocation.name}
                                    </div>
                                    <div
                                      className="px-3"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i
                                        className="ml-1"
                                        onClick={() =>
                                          this.handleDeleteLocation(index)
                                        }
                                      >
                                        <img
                                          src={require("../../../images/nav-icons/baseline_delete_black_24dp.png")}
                                          style={{ width: 25, height: 25 }}
                                        />
                                      </i>
                                    </div>
                                  </div>
                                )
                              )}
                            {this.state.selectedLocations.length ===
                              LOCATION_LIMIT ||
                            this.state.locations.length ===
                              this.state.selectedLocations.length ? (
                              <></>
                            ) : (
                              <select
                                value={null}
                                style={{
                                  borderColor: "#afafaf",
                                }}
                                onChange={(e) => {
                                  if (e.target.value !== null) {
                                    let value = e.target.value;
                                    let location = this.state.locations.find(
                                      (e) => e.id === value
                                    );
                                    let selectedLocations =
                                      this.state.selectedLocations;
                                    selectedLocations.push(location);
                                    this.setState({
                                      selectedLocations,
                                    });
                                  }
                                }}
                              >
                                <option
                                  key="empty"
                                  value={null}
                                  style={{ color: "#afafaf" }}
                                >
                                  Select location
                                </option>
                                {this.state.locations
                                  .filter(
                                    (e) =>
                                      this.state.selectedLocations.filter(
                                        (location) => e.id === location.id
                                      ).length === 0
                                  )
                                  .map((location) => {
                                    return (
                                      <option
                                        key={location.name}
                                        value={location.id + ""}
                                      >
                                        {location.type}:{location.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            )}
                          </div>
                          {this.state.locations.length < LOCATION_LIMIT ? (
                            <div
                              className="btn"
                              style={{
                                padding: "3px 15px",
                                marginTop: "5px",
                                color: "#ffffff",
                                background: "#afafaf",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.setState({
                                  showModal: true,
                                });
                              }}
                            >
                              Add Location
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='row mt-4'>
                                <div className='col'>
                                    <h5 style={{ fontWeight: 'bold' }}>Watch Parties</h5>
                                </div>
                            </div> */}
                  {/* <div className="col-md-6">
                                <FInput
                                    label="Location"
                                    name="watchparty.location"
                                    display="row"
                                    className="placeholder-regular"
                                    placeholder="Enter Venue"
                                    onChange={this.handleInputChange}
                                    value={this.state.watchparty.location}
                                    required
                                />
                            </div>
                            <div className='col-md-6'>
                                <div class="row" >
                                    <div className="form-group col-md-12" >
                                        <ImageUploadButton
                                            label="Venue Image"
                                            text="Upload"
                                            aspectRatio={1}
                                            required={true}
                                            images={[this.state.watchparty.image]}
                                            onSave={(images) => {
                                                this.setState((prevState) => {
                                                    let updateObj = { ...prevState };
                                                    updateObj.watchparty.image = images[0];
                                                    return updateObj
                                                }, () => {
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mb-2'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        Address
                                        {<span style={
                                            {
                                                color: 'red'
                                            }
                                        } > * </span>}</div>
                                    <div className='col-md-8'>
                                        <div className='row' style={{ padding: '5px', borderRadius: '5px', border: '1px solid grey' }}>
                                            <a
                                                id='watchparty'
                                                onClick={this.handleModalChange}
                                                tabIndex="2"
                                                style={{ textDecoration: 'none' }}
                                            >
                                                {this.state.watchparty.locationData.address}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {!this.state.viewMode &&
                                    <div className='row'>
                                        <div className='col-md-4'></div>
                                        <Button style={{ marginTop: '10px' }} onClick={this.locationClear} name='watchparty'>Clear</Button>
                                    </div>
                                }
                            </div>
                            <div className="col-md-6">
                                <FInput
                                    label="Website"
                                    name="watchparty.website"
                                    display="row"
                                    className="placeholder-regular"
                                    placeholder="Website"
                                    onChange={this.handleInputChange}
                                    value={this.state.watchparty.website}
                                />
                            </div> */}

                  {/* <div className='row mt-4'>
                                <div className='col'>
                                    <h5 style={{ fontWeight: 'bold' }}>Tailgates</h5>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <FInput
                                    label="Location"
                                    name="tailgate.location"
                                    display="row"
                                    className="placeholder-regular"
                                    placeholder="Enter Venue"
                                    onChange={this.handleInputChange}
                                    value={this.state.tailgate.location}
                                />
                            </div>
                            <div className='col-md-6'>
                                <div class="row" >
                                    <div className="form-group col-md-12" >
                                        <ImageUploadButton
                                            label="Venue Image"
                                            text="Upload"
                                            aspectRatio={1}
                                            images={[this.state.tailgate.image]}
                                            onSave={(images) => {
                                                this.setState((prevState) => {
                                                    let updateObj = { ...prevState };
                                                    updateObj.tailgate.image = images[0];
                                                    return updateObj
                                                }, () => {
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mb-2'>
                                <div className='row'>
                                    <div className='col-md-4'>Address</div>
                                    <div className='col-md-8'>
                                        <div className='row' style={{ padding: '5px', borderRadius: '5px', border: '1px solid grey' }}>
                                            <a
                                                id='tailgate'
                                                onClick={this.handleModalChange}
                                                tabIndex="2"
                                                style={{ textDecoration: 'none' }}
                                            >
                                                {this.state.tailgate.locationData.address}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {!this.state.viewMode &&
                                    <div className='row'>
                                        <div className='col-md-4'></div>
                                        <Button style={{ marginTop: '10px' }} onClick={this.locationClear} name='tailgate'>Clear</Button>
                                    </div>
                                }
                            </div>
                            <div className="col-md-6">
                                <FInput
                                    label="Website"
                                    name="tailgate.website"
                                    display="row"
                                    className="placeholder-regular"
                                    placeholder="Website"
                                    onChange={this.handleInputChange}
                                    value={this.state.tailgate.website}
                                />
                            </div> */}
                  <div className="row mt-4">
                    <div className="col">
                      <h5 style={{ fontWeight: "bold" }}>Contact</h5>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <FInput
                      label="Website"
                      name="contact.website"
                      display="row"
                      className="placeholder-regular"
                      placeholder="Website (full URL)"
                      onChange={this.handleInputChange}
                      value={this.state.contact.website}
                    />
                  </div>
                  <div className="col-md-6">
                    <FInput
                      label="Email"
                      name="contact.email"
                      display="row"
                      className="placeholder-regular"
                      placeholder="Email"
                      onChange={this.handleInputChange}
                      value={this.state.contact.email}
                    />
                  </div>
                  <div className="col-md-6">
                    <FInput
                      label="Facebook"
                      name="contact.facebook"
                      display="row"
                      className="placeholder-regular"
                      placeholder="Facebook (full URL)"
                      onChange={this.handleInputChange}
                      value={this.state.contact.facebook}
                    />
                  </div>
                  <div className="col-md-6">
                    <FInput
                      label="Instagram"
                      name="contact.insta"
                      display="row"
                      className="placeholder-regular"
                      placeholder="Instagram (full URL)"
                      onChange={this.handleInputChange}
                      value={this.state.contact.insta}
                    />
                  </div>
                  <div className="col-md-6">
                    <FInput
                      label="Twitter"
                      name="contact.twitter"
                      display="row"
                      className="placeholder-regular"
                      placeholder="Twitter (full URL)"
                      onChange={this.handleInputChange}
                      value={this.state.contact.twitter}
                    />
                  </div>

                  <DirectoryGeneral
                    general={this.state.general}
                    handleOptionChange={this.handleOptionChange}
                    handleInputChange={this.handleInputChange}
                  />

                  <DirectoryImages
                    groupImages={this.state.groupImages}
                    handleImageChange={this.handleImageChange}
                  />

                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-success"
                      tabIndex="14"
                      type="success"
                      style={{ backgroundColor: primaryColor }}
                      onClick={() => {
                        this.handleSubmit();
                      }}
                    >
                      {this.state.isSaving ? (
                        <Spin size="small" spinning={true} />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </>
              )}
              {this.state.tab === "getStarted" && (
                <GetStarted
                  history={this.props.history}
                  showModal={this.state.showWelcomeModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  fetchData = async () => {
    return getWebsiteConfig(this.props.adminData.data.id).then(async (data) => {
      if (data) {
        let stateObj = {
          summary: data.groupSummary || "",
          groupOverview: data.groupOverview || "",
          contact: {
            ...(data.social || {}),
            ...(data.contact || {}),
          },
          groupImages: data?.configuration?.groupImages
            ? data?.configuration?.groupImages
            : { image1: "", image2: "", image3: "", image4: "" },
          photo: data.configuration ? data.configuration.photo : "",
          general: {
            ...data.general,
            clubStatus: data.groupType,
            groupSize: (data.general || {}).size,
          },
          selectedLocations:
            (data.sections && data.sections.selectedLocations) || [],
          isPublished: data?.isPublished || false,
        };

        if (data?.isPublished) {
          this.props.dispatch(
            setGettingStartedItemStatus(
              this.props.currentGroup,
              GUIDE_GROW,
              TOPIC_PUBLISH_GROUP,
              true
            )
          );
        }

        let locations = await getLocations(this.props.currentGroup);

        this.setState({
          ...stateObj,
          locations,
        });
      }
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.fetchData().then(() => {
        this.setState({
          isLoading: false,
        });
      });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup] || {},
    currentGroup: state.adminData.currentGroup,
    user: state.user,
  };
};

export default connect(mapStateToProps)(DirectoryAdd);
