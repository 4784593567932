import { countries } from "countries-list";

export const isMobile = () => {
  const userAgent = window?.navigator?.userAgent?.toLowerCase();
  const mobileRegex =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

  const isNarrowScreen = window?.innerWidth <= 768;

  return mobileRegex?.test(userAgent) || isNarrowScreen;
};

export const convertStringObjectToJson = (invalidJsonString) => {
  if (invalidJsonString && invalidJsonString?.length > 0) {
    const jsonString = invalidJsonString.replace(
      /([{,]\s*)([a-zA-Z0-9_]+)(\s*:)/g,
      '$1"$2"$3'
    );

    const validJsonString = jsonString?.replace(
      /:\s*([a-zA-Z0-9_]+)/g,
      ': "$1"'
    );

    return validJsonString ? JSON.parse(validJsonString) : {};
  }
  return {};
};

export const getCountriesList = () => {
  let countryNames = Object?.values(countries)?.map((country) => {
    return { name: country?.name };
  });

  let countryNamesSorted = countryNames?.sort((a, b) =>
    a?.name?.localeCompare(b?.name)
  );

  return countryNamesSorted;
};

export const getPrimaryColor = (data) => {
  let { r, g, b } = data;
  return `rgb(${r}, ${g}, ${b})`;
};
