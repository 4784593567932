import moment from "moment";
import React from "react";

const PackageHistory = ({ timeline, groupCurrency, storeItems }) => {
  const sortedTimeline = Object.values(timeline).sort((a, b) => {
    return Number(b.season) - Number(a.season);
  });

  return (
    <div>
      <table>
        <thead>
          <tr style={{ fontSize: "16px" }}>
            <th style={{ padding: "0 20px 0 20px" }}>Season</th>
            <th style={{ padding: "0 20px 0 20px" }}>Package</th>
            <th style={{ padding: "0 20px 0 20px" }}>Exp Date</th>
            <th style={{ padding: "0 20px 0 20px" }}>Auto Renew</th>
            <th style={{ padding: "0 20px 0 20px" }}>Price</th>
            <th style={{ padding: "0 20px 0 20px" }}>Child</th>
            <th style={{ padding: "0 20px 0 20px" }}>Shipping</th>
            <th style={{ padding: "0 20px 0 20px" }}>Merchandise</th>
          </tr>
        </thead>

        <tbody className="edit-members-purchases-table-body">
          {sortedTimeline?.map((item) => {
            return (
              <tr style={{ fontSize: "14px" }}>
                <td style={{ padding: "0 20px 0 20px" }}>
                  {item?.season || "-"}
                </td>
                <td style={{ padding: "0 20px 0 20px" }}>
                  {item?.name || "-"}
                </td>
                <td style={{ padding: "0 20px 0 20px" }}>
                  {item?.expiryDate
                    ? moment.unix(item?.expiryDate).format("MMM DD, YYYY")
                    : "-"}
                </td>
                <td style={{ padding: "0 20px 0 20px" }}>
                  {item?.offerAutorenewal ? "Y" : "N"}
                </td>
                <td style={{ padding: "0 20px 0 20px" }}>
                  {item?.price?.amount
                    ? `${groupCurrency}${item?.price?.amount}`
                    : "-"}
                </td>
                <td style={{ padding: "0 20px 0 20px" }}>
                  {item?.includesChildPrice
                    ? `${groupCurrency}${item?.childPrice?.amount}`
                    : "-"}
                </td>
                <td style={{ padding: "0 20px 0 20px" }}>
                  {`${item?.price?.shipping != null ? `${groupCurrency}${item.price.shipping}` : "-"} / ${item?.childPrice?.shipping != null ? `${groupCurrency}${item.childPrice.shipping}` : "-"}`}
                </td>

                <td style={{ padding: "0 20px 0 20px" }}>
                  {item?.products
                    ?.map((data) => {
                      const matchingItem = storeItems.find(
                        (storeItem) => storeItem.id === data
                      );
                      return matchingItem ? matchingItem.name : null;
                    })
                    ?.filter(Boolean)
                    ?.join(", ") || "-"}{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PackageHistory;
