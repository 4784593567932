import React, { useEffect } from "react";
import Map from "../../../commons/formFields/Map";
import { Alert, Button, Input, Modal, Spin } from "antd";
import ImageUploadButton from "../../website/ImageUploaderButton";
import { FInput } from "../../../commons/formFields/FInput";
import { FSelect } from "../../../commons/formFields/FSelect";
import { deleteImage } from "../../../../services/firebaseService/endPoints/admin/website";
import useCreateLocation from "./useCreateLocation";
import { db } from "../../../../services/firebaseService/connection";
import { collection, getDocs } from "firebase/firestore";

const TYPES = [
  "Watch Party",
  "Where We Meet",
  "Meet-up",
  "Pub",
  "Hotel",
  "Tailgate",
  "Stadium",
  "Tifo",
  "Volunteering",
];

const CreateLocations = ({ onCloseModal }) => {
  const {
    saving,
    loading,
    childRef,
    formData,
    adminData,
    showModal,
    locationId,
    errorInForm,
    locationData,
    primaryColor,
    locationImage,
    mapUpdate,
    handleMapOK,
    toggleModal,
    handleSubmit,
    updateAddress,
    locationClear,
    setLocationImage,
    handleInputChange,
    handleModalChange,
    handleErrorMessageClose,
  } = useCreateLocation(onCloseModal);

  return (
    <div className="col">
      {errorInForm.isError && errorInForm.visible && (
        <div className="mx-5 mt-2">
          <Alert
            message="Error"
            description={errorInForm.message}
            type="error"
            closable
            afterClose={handleErrorMessageClose}
          />
        </div>
      )}

      {showModal ? (
        <div>
          <Modal
            visible={showModal}
            width="150vh"
            onOk={handleMapOK}
            onCancel={toggleModal}
          >
            {
              <Map
                address={locationData.address}
                onRef={(ref) => {
                  childRef.current = ref;
                }}
                mapUpdate={mapUpdate}
                lat={locationData.latitude}
                long={locationData.longitude}
                place_id={locationData.placeId}
                updateAddress={updateAddress}
              />
            }
          </Modal>
        </div>
      ) : (
        <div></div>
      )}
      <div className="md-5 border-box" style={{ padding: "30px 16px" }}>
        <ul className="nav nav-tabs">
          <li
            className="nav-item"
            style={{
              fontSize: 22,
              fontWeight: "bold",
              borderBottom: `4px solid ${primaryColor}`,
              color: locationId ? "#ffffff" : "#333333",
            }}
          >
            <a>Add Location</a>
          </li>
        </ul>
        <div
          className="border rounded p-3 px-5 mt-4"
          style={{
            backgroundColor: "#ffffff",
          }}
        >
          <div className="row">
            {loading ? (
              <div
                className="flex-center"
                style={{ height: "40vh", width: "100%" }}
              >
                <Spin tip="Loading..." />
              </div>
            ) : (
              <div className="col-md-9">
                <div className="row">
                  <div className={`form-group col-md-12`}>
                    <FSelect
                      label="Location Type"
                      value={formData?.type || ""}
                      onChange={handleInputChange}
                      name="type"
                      dataList={TYPES}
                      dataOnlyValue
                      placeholder="--Select Location Type--"
                      display="row"
                      tabIndex="1"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={`form-group col-md-12`}>
                    <FInput
                      label="Name"
                      value={formData?.name || ""}
                      onChange={handleInputChange}
                      name="name"
                      display="row"
                      required
                      tabIndex="2"
                      maxLength={25}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={`form-group col-md-12`}>
                    <FInput
                      label="Location URL"
                      value={formData?.url || ""}
                      onChange={handleInputChange}
                      name="url"
                      display="row"
                      tabIndex="2"
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-4">
                    Location Address<span style={{ color: "#ff0000" }}>*</span>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-3">
                        <div
                          className="row"
                          style={{
                            padding: "3px 5px",
                            borderRadius: "5px",
                            border: "1px solid #afafaf",
                            background: "#afafaf",
                            fontSize: 14,
                            justifyContent: "center",
                          }}
                        >
                          <a
                            onClick={handleModalChange}
                            tabIndex="2"
                            style={{ textDecoration: "none" }}
                          >
                            Select Address
                          </a>
                        </div>
                      </div>
                      <div className="form-group col-md-7">
                        <div className="col">
                          <FInput
                            value={locationData?.address || ""}
                            name="location"
                            display="row"
                            tabIndex="2"
                            required
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <Button
                          style={{ padding: "3px 15px", background: "#afafaf" }}
                          onClick={locationClear}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    Location Image
                    <span style={{ color: "#ff0000" }}>*</span>
                  </div>
                  <div className="form-group col-md-8">
                    <div className="row">
                      <ImageUploadButton
                        text="Upload Image"
                        onDelete={(file) => {
                          if (file.url) {
                            let path = `${adminData.groupName}/locations/`;
                            deleteImage(
                              `${path}/`,
                              "locationImage",
                              adminData.id
                            );
                          }
                        }}
                        images={[locationImage]}
                        aspectRatio={1}
                        onSave={(images) => {
                          images && setLocationImage(images[0]);
                        }}
                      />
                      <p style={{ fontSize: 14 }}>
                        Used on website and directory. 1x1 aspect ratio.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">Location Details</div>
                  <Input.TextArea
                    className="col-md-8"
                    tabIndex="2"
                    maxLength={250}
                    style={{
                      fontSize: `16px`,
                    }}
                    placeholder={
                      "(optional) Enter brief description (max 250 chars). Used on website and directory."
                    }
                    value={formData?.details}
                    name="details"
                    onChange={handleInputChange}
                    rows={4}
                  />
                </div>
                <div className="row mt-4">
                  <div className="col-md-4">
                    Is this the club's primary stadium?
                  </div>
                  <div className="form-group">
                    <input
                      type="checkbox"
                      checked={formData?.isClubPrimaryStadium}
                      name="collectDonations"
                      style={{ height: "20px", width: "20px", borderRadius: 5 }}
                      onChange={(e) => {
                        handleInputChange(
                          undefined,
                          "isClubPrimaryStadium",
                          e.target.checked
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-4"></div>
                  <div className="col-md-8 d-flex justify-content-center">
                    <button
                      className="btn btn-success"
                      tabIndex="14"
                      type="success"
                      style={{
                        backgroundColor: primaryColor,
                        borderColor: primaryColor,
                        padding: "3px 30px",
                      }}
                      onClick={handleSubmit}
                    >
                      {saving ? (
                        <Spin size="small" spinning={true} />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateLocations;
