import { Table, Avatar, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import {
  getCurrencyUnicode,
  postUpdateCDNUtil,
  pushToArray,
} from "../../../helperFunctions/util";
import { db } from "../../../services/firebaseService/connection";
import { getLinkedMember } from "../../../services/indexedDb/members";
// import CustomDropDown from '../../commons/CustomDropDown';
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "@firebase/firestore";
import { AntdTableScrollXaxisTop } from "antd-table-scroll-xaxis-top";
import EditMember from "./EditMemberModal/EditMember";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import {
  GUIDE_ONBOARD,
  TOPIC_ASSIGN_LEADERS,
} from "../../../constants/gettingStarted";
import { connect } from "react-redux";

import logger from "../../../utils/logger";
import { isEmailValid } from "../../../utils/validation";
class MemberTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRoles: [
        "admin",
        "member",
        "ticket-scanner",
        "capo",
        "leader",
        "parent-admin",
      ],
      userRole: "",
      searchKey: "",
      editableRecordId: "",
      linkedMember: null,
      editedRecord: {},
      formLayout: {},
      tableCSS: null,
    };
  }

  handleModalOpen = async (id, familyMembershipId) => {
    if (id && familyMembershipId) {
      getLinkedMember(this.props.groupData.id, familyMembershipId, id).then(
        (linkedMember) => {
          this.setState({
            editableRecordId: id,
            linkedMember: linkedMember,
          });
        }
      );
      return;
    }
    this.setState({ editableRecordId: id, linkedMember: null });
  };

  handleOptionChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  updateEmailInUsers = async (memberId, updatedEmail) => {
    try {
      const memberDocRef = doc(db, "users", memberId);
      const docSnapshot = await getDoc(memberDocRef);

      if (docSnapshot.exists()) {
        await updateDoc(memberDocRef, { email: updatedEmail });
      } else {
        logger.log("No such document in users collection!");
      }
    } catch {
      logger.error("Failed to update users collection email!");
    }
  };

  updateEmailListInInvites = async (groupId, oldEmail, updatedEmail) => {
    try {
      const docRef = doc(db, "invite_emails", groupId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        let emails = docSnapshot.data().emails || [];
        const emailIndex = emails.indexOf(oldEmail);

        if (emailIndex !== -1) {
          emails[emailIndex] = updatedEmail;
          await updateDoc(docRef, { emails });
        } else {
          logger.log("Email not found in the invite_emails list.");
        }
      } else {
        logger.log("No such document in invite_emails!");
      }
    } catch (error) {
      logger.error("Failed to update invite email details: ", error);
    }
  };

  saveDetail = async (updatedData, id, email) => {
    if (updatedData.fullName) {
      updatedData = {
        ...updatedData,
        name: updatedData.fullName,
      };
    }

    if (updatedData.email === "") {
      alert("Email cannot be empty");
      return;
    }

    if (
      updatedData?.email &&
      updatedData?.email?.length > 0 &&
      !isEmailValid(updatedData.email)
    ) {
      alert("Please enter a valid Email ID");
      return;
    }

    if (updatedData.fullName === "") {
      alert("Name cannot be empty");
      return;
    }

    this.props.editMemberInLocalDb({ id, ...updatedData });

    if (updatedData?.userRole === "admin") {
      this.props.setGettingStartedStatus(this.props.groupData.id);
    }

    // if inv emails -> update inv emails
    const inviteEmailsDocSnap = await getDocs(
      query(
        collection(db, "invite_emails", this.props.groupData.id, "userDetails"),
        where("email", "==", email)
      )
    ).then((snap) => {
      return snap.docs[0];
    });

    if (updatedData.hasOwnProperty("package")) {
      const selectedPackage = this.props?.packages?.find(
        (pkg) => pkg?.id === updatedData?.package
      );

      updatedData = {
        ...updatedData,
        packageSeason: selectedPackage?.season
          ? String(selectedPackage?.season)
          : "",
        membershipExpiryDate: selectedPackage?.packageExpiryDate || "",
      };
    }

    if (inviteEmailsDocSnap && inviteEmailsDocSnap.exists()) {
      let data = inviteEmailsDocSnap.data() || {};

      let updateObj = {
        ...data,
        ...updatedData,
      };

      if (!updatedData.tshirtSize) {
        delete updateObj.tshirtSize;
      }

      await updateDoc(inviteEmailsDocSnap.ref, { ...updateObj }).catch(
        (err) => {
          logger.error(
            "Error while updating userDetails in invite_emails",
            err
          );
        }
      );

      // update email in invite_emails collection
      if (updatedData.email) {
        await this.updateEmailListInInvites(
          this.props.groupData.id,
          email,
          updatedData.email
        );
      }
    }
    if (typeof id === "string") {
      // update email in users collection
      const usersDocRef = doc(db, "users", id);
      const usersDocSnapshot = await getDoc(usersDocRef);

      if (updatedData.email && usersDocSnapshot && usersDocSnapshot.exists()) {
        await this.updateEmailInUsers(id, updatedData.email);
      }

      if (!updatedData.tshirtSize) {
        updatedData.tshirtSize = deleteField();
      }

      if (updatedData.hasOwnProperty("hometown")) {
        let homeTown = updatedData.hometown;
        if (!homeTown) {
          homeTown = "";
        }
        updateDoc(doc(db, "users", id), { homeTown });

        delete updatedData.homeTown;
      }

      // update email and other data in usergroup collection
      updateDoc(doc(db, "userGroup", this.props.groupData.id, "members", id), {
        ...updatedData,
      });

      if (updatedData.userRole) {
        postUpdateCDNUtil(this.props.groupData.id);
      }

      let websiteCollectionRef = doc(db, "website", this.props.groupData.id);
      if (updatedData.title) {
        getDoc(websiteCollectionRef).then((doc) => {
          if (doc.exists() && doc.data().about) {
            let about = doc.data().about;
            let leadership = about.leadership;
            if (leadership && Array.isArray(leadership.leaders)) {
              let requiredDoc = leadership.leaders.find(
                (value) => value.id === id
              );
              if (requiredDoc) {
                requiredDoc.title = updatedData.title;
                websiteCollectionRef.update({ about });
              }
            }
          }
        });
      }
    }

    this.handleModalOpen();
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.groupData.regCodeRole === "fan" &&
      !state.userRoles.includes("fan")
    ) {
      let userRoles = [...state.userRoles, "fan"];
      return { userRoles };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groupData.id !== this.props.groupData.id) {
      if (
        this.props.groupData.regCodeRole === "fan" &&
        !this.state.userRoles.includes("fan")
      ) {
        let userRoles = [...this.state.userRoles, "fan"];
        this.setState({ userRoles });
      } else {
        if (this.state.userRoles.includes("fan")) {
          let userRoles = [...this.state.userRoles];
          userRoles.splice(userRoles.indexOf("fan"), 1);
          this.setState({ userRoles });
        }
      }
    }
  }

  componentDidMount() {
    this.changeHeaderColor();
    window.onload = this.changeHeaderColor;
  }

  changeHeaderColor = () => {
    let primaryColor = "";
    if (
      this.props.groupData.configuration &&
      this.props.groupData.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = primaryColor;
    });
  };

  render() {
    let customFields = this.props.formLayout.customFieldDetails;
    let primaryColor = "";
    if (
      this.props.groupData.configuration &&
      this.props.groupData.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let groupCurrencyISO = this.props.groupData.paymentDetails
      ? this.props.groupData.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);
    let columns = [
      {
        title: "",
        width: 150,
        className: "header-white",
        render: (data) => {
          return (
            <>
              {data && data.id && (
                <i
                  className="ml-3"
                  onClick={() =>
                    this.handleModalOpen(data.id, data.familyMembershipId)
                  }
                >
                  <img
                    src={require("../../../images/nav-icons/baseline_edit_black_24dp.png")}
                    style={{ width: 25, height: 25 }}
                  />
                </i>
              )}
              {data && data.lockStatus && (
                <i className="fa fa-lock cursor-pointer ml-3"></i>
              )}
              {data && data.notes && (
                <Tooltip
                  placement="topLeft"
                  title={() => (
                    <div
                      style={{
                        height: "fit-content",
                        width: "400px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {data.notes}
                    </div>
                  )}
                >
                  <i className="ml-3">
                    <img
                      src={require("../../../images/text_snippet_black_24dp.svg")}
                      style={{ width: 25, height: 25 }}
                    />
                  </i>
                </Tooltip>
              )}
            </>
          );
        },
        // fixed: 'left'
      },
      {
        title: "Name",
        dataIndex: "name",
        className: "header-white",
        sorter: true,
        sortOrder:
          this.props.sorter.field === "name" && this.props.sorter.order,
        render: (name, record) => {
          return (
            <div className="d-flex">
              {record.profileImage ? (
                <Avatar size="large" src={record.profileImage} />
              ) : (
                <Avatar
                  size="large"
                  style={{ backgroundColor: primaryColor || "#ff4040" }}
                >
                  {`${name}`.charAt(0).toUpperCase()}
                </Avatar>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginLeft: 30, fontWeight: "bold" }}>
                  {name
                    ? name
                    : record.firstName
                      ? `${record.firstName} ${record.lastName}`
                      : ""}
                </span>
                <span style={{ marginLeft: 30, fontSize: 14 }}>
                  {record.title || ""}
                </span>
                <span style={{ marginLeft: 30, fontSize: 14 }}>
                  {record.userRole || ""}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        title: "Email",
        width: window.screen.width > 500 ? 300 : 150,
        dataIndex: "email",
        className: "header-white",
        sorter: true,
        sortOrder:
          this.props.sorter.field === "email" && this.props.sorter.order,
      },
      {
        title: (
          <div>
            <Tooltip
              placement="topLeft"
              title={
                <div style={{ width: 400 }}>
                  Phone number displayed here is requested when selling
                  membership packages. Chant does not display the phone number
                  used for app registration as that is considered a 'username',
                  not a number used to contact members.
                </div>
              }
            >
              <div className="flex-center" style={{ gap: "8px" }}>
                <p className="flex-center" style={{ marginBottom: "0" }}>
                  Phone
                </p>
                <div>
                  <i className="fa fa-question-circle-o" />
                </div>
              </div>
            </Tooltip>
          </div>
        ),
        className: "header-white",
        width: window.screen.width > 500 ? 200 : 150,
        dataIndex: "phone",
      },
      {
        title: "Birthday",
        width: 150,
        dataIndex: "birthday",
        className: "header-white",
        render: (data) => {
          if (data && Object.keys(data).length > 0) {
            let birthday = `${data.month} ${data.date}`;
            if (data.year) {
              birthday = `${birthday} ${data.year}`;
            }
            return <span>{birthday}</span>;
          }
          return "";
        },
      },
      {
        title: "Hometown",
        dataIndex: "hometown",
        key: "hometown",
        width: 150,
        className: "header-white",
      },
      // {
      //     title: 'Shipped?',
      //     dataIndex: 'isShipped',
      //     render: (data, record) => (<Checkbox checked={data} onChange={(e) => {
      //         let isShipped = e.target.checked;

      //         let updateKey = 'isShipped';
      //         let updateValue = isShipped;

      //         let id = record.id;

      //         let email = record.email;

      //         if (!record.id) {
      //             let membershipNumber = record.membershipNumber;
      //             if (this.props.tableData) {
      //                 let currentRecord = this.props.tableData.find(record => record.children && record.children.find(child => child.membershipNumber === membershipNumber));
      //                 id = currentRecord.id;
      //                 email = currentRecord.email;
      //                 updateKey = 'children';
      //                 let updatedChildren = currentRecord.children;
      //                 updatedChildren.find((child) => child.membershipNumber === membershipNumber).isShipped = isShipped;
      //                 updateValue = updatedChildren;
      //             }
      //         }

      //         this.props.editMemberInLocalDb({ id, [updateKey]: updateValue });

      //         if (typeof id === 'number') {
      //             let docRef = db.collection('invite_emails').doc(this.props.groupData.id);

      //             docRef.get().then(doc => {
      //                 if (doc.exists) {
      //                     let userDetails = doc.data().userDetails;
      //                     if (userDetails) {
      //                         let userDetailKeys = Object.keys(userDetails).filter(key => userDetails[key].email === email);
      //                         let userDetailObj = userDetailKeys.length > 0 ? userDetails[userDetailKeys[0]] : {};
      //                         let key = userDetailKeys.length > 0 ? userDetailKeys[0] : null;
      //                         if (key) {
      //                             let updatedUserDetailObj = {
      //                                 ...userDetailObj,
      //                                 [updateKey]: updateValue
      //                             }

      //                             userDetails[key] = updatedUserDetailObj;

      //                             docRef.update({ userDetails });
      //                         }
      //                     }
      //                 }
      //             })
      //         } else {

      //             db.collection('userGroup').doc(this.props.groupData.id).collection('members').doc(id).update({
      //                 [updateKey]: updateValue
      //             });
      //         }

      //     }}></Checkbox>)
      // },
      {
        title: "Street",
        key: "streetName",
        dataIndex: "address1",
        className: "header-white",
        // sorter: true,
        // sortOrder:
        //   this.props.sorter.columnKey === "streetName" &&
        //   this.props.sorter.order,
        render: (data) => <span style={{}}>{data}</span>,
      },
      {
        title: "Address 2",
        key: "address2",
        className: "header-white",
        dataIndex: "address2",
        render: (data) => <span style={{}}>{data}</span>,
      },
      {
        title: "City",
        key: "city",
        className: "header-white",
        dataIndex: "city",
        render: (data) => <span style={{}}>{data}</span>,
      },
      {
        title: "State",
        key: "state",
        className: "header-white",
        dataIndex: "state",
        render: (data) => <span style={{}}>{data}</span>,
      },
      {
        title: "Country",
        key: "country",
        className: "header-white",
        dataIndex: "country",
        render: (data) => <span style={{}}>{data}</span>,
      },
      {
        title: "Zip",
        key: "zip",
        className: "header-white",
        dataIndex: "zip",
        render: (data) => <span style={{}}>{data}</span>,
      },

      {
        title: (
          <div>
            <p style={{ marginBottom: 0 }}>Member</p>
            <p style={{ marginBottom: 0 }}>Since</p>
          </div>
        ),
        dataIndex: "memberSince",
        className: "header-white",
        sorter: true,
        sortOrder:
          this.props.sorter.field === "memberSince" && this.props.sorter.order,
      },
      {
        title: "Membership #",
        dataIndex: "membershipNumber",
        className: "header-white",
        sorter: true,
        sortOrder:
          this.props.sorter.field === "membershipNumber" &&
          this.props.sorter.order,
      },
      {
        title: (
          <div>
            <p style={{ marginBottom: 0 }}>Official Club</p>
            <p style={{ marginBottom: 0 }}>Member #</p>
          </div>
        ),
        key: "officialClubMember",
        className: "header-white",
        dataIndex: "officialClubMember",
      },
      {
        title: "Email?",
        key: "joinedEmailList",
        className: "header-white",
        dataIndex: "joinedEmailList",
        render: (data) => <span style={{}}>{data ? "Yes" : "No"}</span>,
      },
      {
        title: "Volunteer?",
        key: "notifyForVolunteerOpportunities",
        className: "header-white",
        dataIndex: "shareVolunteerOpportunities",
        render: (data) => <span style={{}}>{data ? "Yes" : "No"}</span>,
      },
      {
        title: "Season Ticket",
        key: "seasonTicketHolder",
        className: "header-white",
        dataIndex: "seasonTicketHolder",
        render: (data) =>
          data &&
          Object.keys(data).length > 0 &&
          `${data.section || data.season || ""}/${data.seats || data.noOfSeats || ""}`,
      },

      {
        title: (
          <div>
            <p style={{ marginBottom: 0 }}>Registration </p>
            <p style={{ marginBottom: 0 }}>(Group)</p>
          </div>
        ),
        key: "registrationDate",
        className: "header-white",
        dataIndex: "registrationDate",
        render: (timestamp) => {
          if (timestamp) {
            let date = moment.unix(timestamp);
            if (date.isValid()) {
              date = date.format("MM-DD-YY");
            } else {
              date = "";
            }
            return <span>{date}</span>;
          }
        },
        sorter: true,
        sortOrder:
          this.props.sorter.field === "registrationDate" &&
          this.props.sorter.order,
      },
      {
        title: (
          <div>
            <p style={{ marginBottom: 0 }}>Registration </p>
            <p style={{ marginBottom: 0 }}>(Chant)</p>
          </div>
        ),
        key: "registeredOnChantDate",
        className: "header-white",
        dataIndex: "registeredOnChantDate",
        render: (timestamp) => {
          if (timestamp) {
            let date = moment.unix(timestamp);
            if (date.isValid()) {
              date = date.format("MM-DD-YY");
            } else {
              date = "";
            }
            return <span>{date}</span>;
          }
        },
        sorter: true,
        sortOrder:
          this.props.sorter.field === "registeredOnChantDate" &&
          this.props.sorter.order,
      },
      {
        title: "Chapter",
        width: 200,
        className: "header-white",
        dataIndex: "chapter",
      },
      {
        title: "Referred By",
        width: 200,
        className: "header-white",
        dataIndex: "referredBy",
      },
    ];

    if (this.props.packages && this.props.packages.length > 0) {
      columns.splice(2, 0, {
        title: "Package",
        key: "package",
        className: "header-white",
        dataIndex: "package",
        render: (pack, state) => {
          let packageDetails = pack
            ? this.props.packages.find((packObj) => pack === packObj.id)
            : undefined;
          let packageName = "";
          if (packageDetails) {
            packageName = packageDetails.name;
          }

          let isExpired = false;

          if (
            state?.membershipExpiryDate ||
            packageDetails?.packageExpiryDate
          ) {
            const expiryDate =
              state?.membershipExpiryDate || packageDetails?.packageExpiryDate;
            isExpired = moment.unix(expiryDate).isBefore(moment());
          }

          return (
            <div className="d-flex">
              <span style={{}}>{!isExpired ? packageName : ""}</span>
            </div>
          );
        },
        sorter: true,
        sortOrder:
          this.props.sorter.field === "package" && this.props.sorter.order,
      });
    }

    if (
      this.props.packages &&
      this.props.packages.length > 0 &&
      this.props.tableData.find(
        (item) => item?.subscriptionDetail?.status === "active"
      )
    ) {
      columns.splice(3, 0, {
        title: "Auto-renewal",
        width: 150,
        dataIndex: "subscriptionDetail",
        className: "header-white",
        render: (data) => <p>{data?.status === "active" ? "Yes" : ""}</p>,
      });
    }

    Object.keys(customFields || {}).map((key) => {
      let fieldTitle = customFields[key].fieldTitle;
      let dataIndex = key;
      let col = {
        title: fieldTitle,
        key: key,
        className: "header-white",
        dataIndex,
      };

      columns.push(col);
    });

    this.changeHeaderColor();

    let data = (this.props.tableData || []).map((row) => {
      return {
        key: row.email,
        ...row,
      };
    });

    data = JSON.parse(JSON.stringify(data));
    let editableRecord = this.state.editableRecordId
      ? data.filter((record) => record.id === this.state.editableRecordId)[0]
      : {};

    return (
      <>
        {this.state.editableRecordId && (
          <EditMember
            editableRecord={editableRecord}
            editableRecordId={this.state.editableRecordId}
            handleModalOpen={this.handleModalOpen}
            groupId={this.props.groupData.id}
            saveDetail={this.saveDetail}
            primaryColor={primaryColor}
            linkedMember={this.state.linkedMember}
          />
        )}

        <div className="mt-3">
          <AntdTableScrollXaxisTop>
            <Table
              className={`table-backdrop`}
              columns={columns}
              loading={this.props.loading}
              dataSource={data}
              onExpand={this.changeHeaderColor}
              pagination={
                this.props.pagination &&
                this.props.pagination.total > this.props.pagination.pageSize
                  ? this.props.pagination
                  : false
              }
              onChange={this.props.onChange}
              scroll={{ x: "max-content" }}
              rowKey="id"
              // size="small"
              bodyStyle={{
                fontSize: window.screen.width > 500 ? "18px" : "12px",
                backgroundColor: "#ffffff",
                // boxShadow: "0px 3px 10px 1px",
              }}
            />
          </AntdTableScrollXaxisTop>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGettingStartedStatus: (groupId) =>
      dispatch(
        setGettingStartedItemStatus(
          groupId,
          GUIDE_ONBOARD,
          TOPIC_ASSIGN_LEADERS,
          true
        )
      ),
  };
};

export default connect(null, mapDispatchToProps)(MemberTable);
